.chat {
    display: grid;
    place-items: center;
    background-color: white;
    height: 100vh;
}

.chat_body {
    display: flex;
    background-color: #ededed;
    height: 90vh;
    width: 90vw;
    box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex: 0.35;
}

.sidebar_header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgray
}

.sidebar_search {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 50px;
    padding: 10px;
}

.sidebar_searchContainer {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.sidebar_searchContainer>input {
    border: none;
    margin-left: 10px;
}

.sidebar_searchContainer>input:focus {
    outline: none;
}

.sidebar_searchContainer>.MuiSvgIcon-root {
    color: gray;
    margin-left: 10px;
}

.sidebar_chats {
    flex: 1;
    background-color: white;
    overflow: scroll;
}

.sidebarChat {
    display: flex;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}

.sidebarChat:hover {
    background-color: #ebebeb;
}

.sidebarChat_info {
    font-size: 16px;
    margin-bottom: 8px;

}

.sidebarChat_info {
    margin-left: 15px;
}

.inbox {
    flex: 0.65;
    display: flex;
    flex-direction: column;
}

.inbox_header {
    padding: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.inbox_headerInfo {
    flex: 1;
    padding-left: 20px;
}

.inbox_headerInfo>h3 {
    margin-bottom: 3px;
}

.inbox_body {
    padding: 30px;
    overflow: scroll;
    flex: 1;
}

.inbox_message {
    position: relative;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    width: fit-content;
}

.message_receiver {
    margin-left: auto;
}

.inbox_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    border-top: 1px solid lightgray;
}

.inbox_footer>form {
    flex: 1;
    display: flex;
}

.inbox_footer>form>input {
    flex: 1;
    border: none;
    border-radius: 30px;
    padding: 10px;
    margin: 10px;

}

.inbox_footer>form>input:focus {
    outline: none;
}

.inbox_footer>form>button {
    display: none;
}

/* Chat.css */

.hamburger_icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    display: none;
    /* Hide the hamburger by default */
}

.close_icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

/* Add a media query for medium and small screens */
@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 70%;
        max-width: 300px;
        background-color: #fff;
        z-index: 1000;
        display: none;
        /* Hide the sidebar by default */
        transition: transform 1.5s ease-in-out;
        overflow-y: scroll;
        /* Added overflow-y: scroll */
    }

    .hamburger_icon {
        display: block;
        /* Show the hamburger on small screens */
    }

    .sidebar_visible {
        display: block;
        /* Show the sidebar when it should be visible */
        transform: translateX(0);
    }

    .inbox {
        width: 100%;

    }
}

.sidebar_button {
    position: absolute;
    top: 4rem;
    right: 2rem;
    margin-bottom: 40px;
    background-color: #2eadb4;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    z-index: 10;
    display: none;
}

.sidebar_button:hover {
    background-color: #1a9399;
}

@media (min-width: 769px) {
    .sidebar_visible {
        transform: none;
    }

    .sidebar {
        transition: transform 1.5s ease-in-out;
    }
}

@media (max-width: 768px) {
    .sidebar_button {
        display: block;
    }

    .sidebar_visible {
        transform: none;
    }
}