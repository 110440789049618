.inbox {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.inbox_header {
    background: linear-gradient(135deg, #247a67, #1bcedf);
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.buttondiv {
    background: linear-gradient(135deg, #247a67, #1bcedf);
}

.inbox_headerInfo {
    align-items: center;
}

.inbox_body {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    background-color: #f8f8f8;
}

.inbox_message {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    max-width: 70%;
    background-color: #ffffff;
}

.message_receiver {
    align-self: flex-end;
    background-color: #1bcedf;
    color: #ffffff;
}

.inbox_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
}

.inbox_footer input {
    flex: 1;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 1rem;
    outline: none;
    margin-right: 0.5rem;
}

.inbox_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.cross_icon,
.check_icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cross_icon:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.check_icon:hover {
    background-color: rgba(85, 255, 85, 0.1);
}


@media (max-width: 768px) {
    .inbox_wrapper {
        margin-left: 0;
        border-radius: 0;
    }

    .inbox_header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .cross_icon_container {
        top: 0.5rem;
        right: 0.5rem;
    }
}