@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.circle {
    animation: fadeIn 2s ease-in-out;
}

.logo {
    animation: fadeIn 2s ease-in-out;
}