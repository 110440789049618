.sidebarChat {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #e2e2e2;
    transition: background-color 0.2s;
}

.sidebarChat:hover {
    background-color: rgba(27, 206, 223, 0.1);
}

.sidebarChat_info {
    margin-left: 1rem;
    flex-grow: 1;
    /* Add this line */
}

.sidebarChat_info h2 {
    margin-bottom: 0.25rem;
}

.sidebarChat_info p {
    font-size: 0.875rem;
    color: #777;
}

.textContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .sidebarChat_info h2 {
        font-size: 1rem;
    }

    .sidebarChat_info p {
        font-size: 0.75rem;
    }
}

.hamburger_icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

/* Add a media query for medium and small screens */
@media (max-width: 768px) {
    .sidebar {
        width: 4rem;
        overflow-x: hidden;
    }
}