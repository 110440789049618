.carousel .control-arrow {
    background-color: transparent;
    color: #10B981;
    /* This is the green color you want */
}

.carousel .control-prev.control-arrow:hover,
.carousel .control-next.control-arrow:hover {
    background-color: rgba(16, 185, 129, 0.5);
    /* Add a green background color with transparency on hover */
}