@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fadeIn {
    animation: fadeIn 1s ease-out 1;
    animation-fill-mode: forwards;
}

/* Other styles */

.card {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.card {
    position: relative;
    transition: transform 0.3s;
}

.card:hover {
    transform: scale(2.05);
}