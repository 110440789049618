.custom-class {
    height: 100vh;
    margin: 0;
    overflow-y: hidden;
    /* Prevent vertical scrolling */
}


.button-diagonal {
    position: relative;
    background-color: #141414;
    border: 2px solid #0E9A0E;
    overflow: hidden;
}

.button-diagonal::before {
    content: "";
    position: absolute;
    bottom: 10%;
    left: 10%;
    right: 10%;
    top: -100%;
    background: #0E9A0E;
    transform: skewX(-45deg) translateX(-100%);
    transition: transform 0.5s;
    /* increased transition time to make it slower */
}

.button-diagonal:hover::before {
    transform: skewX(-45deg) translateX(0);
}

.button-diagonal:hover>span {
    color: black;
}