.login-button:active {
    background: white;
    background-size: 200%;
    transition: background-position 0.5s;
    background-image: linear-gradient(to right, white 50%, green 50%);
    background-position: right;
}

.signup-button:active {
    background: white;
    background-size: 200%;
    transition: background-position 0.5s;
    background-image: linear-gradient(to right, white 50%, transparent 50%);
    background-position: right;
}